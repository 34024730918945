













































































































































































































































































































































































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import {
  drawBg,
  drawText,
  getStringWidth,
  splitData,
  isIntersect,
} from "@/utils/biaozhu";
import {
  GetBiaozhuOption,
  GetTuijianType,
  GetEntity,
  GetShuxing,
  GetSechemaList,
} from "@/request/mark";
@Component({})
export default class Name extends Vue {
  @Prop()
  private type: any;
  @Prop()
  private parentData: any;
  @Watch("parentData.data", { immediate: true })
  private parentDataChange() {
    if (!this.parentData.content) {
      return;
    }
    this.data = this.parentData.content;
    this.biaozhuData = this.parentData.data;
    this.createContent();
  }
  private dialogType: any = "新建"; // 弹框类型,新建或者编辑
  private data: any = "";
  private treeData: any = []; // 当前语义类型树
  private yuyileixingList: any[] = []; //语义类型可选的列表
  private guanxiList: any[] = []; //关系类型可选的列表
  private biaozhuData: any = [];
  private contentHeight: any = 400; // 标注内容区域高度，会实时计算
  private W: any = 0; // 内容区域宽度
  private fontSize: any = 16; // 文字大小
  private linePath: any = "";
  private allString: any = ""; // 所有原文内容字符串
  private strArrData: any = []; // 字符串数组，一个字符为一项
  private defaultLeft: any = 60; // 默认的左间距，给标记留点溢出空间
  private defaultRight: any = 80; // 默认的右间距，给标记留点溢出空间。页面有滚动条时会占据掉15左右，所以右边会比左边多给点
  private defaultTop: any = 20; // 默认的行间距
  private startPoint: any = {}; // 鼠标按下的坐标
  private endPoint: any = {}; // 鼠标抬起的坐标
  private lineicon: any = "\n"; // 换行标识符
  private lineData: any = []; // 行上方间距，根据标记的内容多少自动计算
  private startIndex: any = 0; // 类型标注计算长度会用到
  private endIndex: any = 0; // 内容换行时会用到，类型标注计算长度会用到
  private biaozhuResult: any = []; // 处理后的标注的内容，处理过可以直接绘制的
  private oneLineBiaozhuH: any = 40; // 每行标注高度
  private drawing: any = false; // 是否正在绘制中,为了方便鼠标离开绘制区域时能够及时结束绘制
  private startDrawLine: any = false; // 是否在画关系标注
  private drawineData: any = {
    to: {},
    from: {},
    关系类型: {},
    备注: "",
  }; // 关系标注绘制过程中的数据
  private dialogData: any = {
    语义类型: {},
  };
  private currentSelectText: any = "";
  private ifShowBiaozhuLineDialog: any = false; // 是否打开新建语义关系标注
  private ifShowBiaozhuTextDialog: any = false; // 是否打开新建语义类型标注
  private yuyiData: any = [];
  private guanxiOptions: any = [];
  private optionsData: any = [];
  private options: any = [];
  private shuxingOptions: any = [];
  private clickData: any = {
    from: { data: {} },
    to: { data: {} },
  }; // 点击的类型标注或关系标注，编辑和删除的时候会用到,
  private defaultProps: any = {
    label: "域名称",
  };
  private guanxiProps: any = {
    label: "relation",
  };
  private get ifCompose() {
    if (this.$store.state.ifCompose) {
      // // 禁掉浏览器的选中文字效果
      // document.onselectstart = function () {
      //   return false;
      // };
      // 禁掉浏览器的点击右键出现菜单功能
      document.oncontextmenu = function () {
        return false;
      };
    } else {
      // document.onselectstart = function () {
      //   return true;
      // };
      document.oncontextmenu = function () {
        return true;
      };
    }
    return this.$store.state.ifCompose;
  }
  private handleNodeClick(data: any, node: any) {
    this.dialogData["语义类型"] = data;
  }
  private handleGuanxiNodeClick(data: any, node: any) {
    if (data.id) {
      const obj = JSON.parse(JSON.stringify(data));
      obj._id = data.id;
      obj.label = data.relation;
      this.drawineData["关系类型"] = obj;
    }
  }
  private handleMouseDown(e: any) {
    if (!this.ifCompose) {
      return;
    }
    if (this.ifShowBiaozhuLineDialog || this.ifShowBiaozhuTextDialog) {
      return;
    }
    // // 禁掉浏览器的选中文字效果
    // document.onselectstart = function () {
    //   return false;
    // };
    // // 禁掉浏览器的点击右键出现菜单功能
    // document.oncontextmenu = function () {
    //   return false;
    // };
    // 用来判断双击
    if (!this.clickData.from.which) {
      this.clickData.from.which = e.which;
      this.clickData.to = { data: {} };
      setTimeout(() => {
        if (!this.clickData.to.which) {
          this.clickData.from = { data: {} };
          this.clickData.to = { data: {} };
        }
      }, 1000);
    } else {
      this.clickData.to.which = e.which;
    }
    // 重置数据
    this.startPoint = {};
    this.endPoint = {};
    this.drawing = false;
    let isInBiaozhu: any = false;
    const d: any = this.ifInBiaozhu(e);
    // 需要判断点击区域是否是类型标注，如果是需要绘制关系标注的线
    if (d) {
      if (this.clickData.to.which) {
        this.clickData.to.data = d;
      } else {
        this.clickData.from.data = d;
      }
      if (d.type === "text") {
        if (this.startDrawLine) {
          this.drawineData.to = d;
        } else {
          this.drawineData.from = d;
        }
        isInBiaozhu = true;
      }
    }
    if (
      this.clickData.from.data.id &&
      this.clickData.from.data.id === this.clickData.to.data.id
    ) {
      if (this.clickData.from.which == 1 && this.clickData.to.which == 1) {
        if (d.type == "text") {
          this.dialogData = JSON.parse(JSON.stringify(d));
          this.openBiaozhuText();
        } else if (d.type == "line") {
          this.drawineData = JSON.parse(JSON.stringify(d));
          this.dialogType = "编辑";
          this.getTreeOption("关系类型");
        }
        this.linePath = "";
        this.drawing = false;
        this.startDrawLine = false;
        return;
      }
    }
    if (!this.startDrawLine) {
      if (isInBiaozhu) {
        this.startDrawLine = true;
        return;
      }
    } else {
      this.linePath = "";
      this.startDrawLine = false;
      if (isInBiaozhu) {
        if (this.drawineData.from.id == this.drawineData.to.id) {
          this.resetDrawData();
        } else {
          this.dialogType = "新建";
          this.getTreeOption("关系类型");
        }
      }
      return;
    }
    // 记录开始绘制行为
    this.drawing = true;
    // 记录绘制起点坐标信息
    const line: any = this.getLineNum(e.offsetY);
    const pointY = this.lineData[line - 1].top - this.fontSize;
    this.startPoint = {
      x: e.offsetX,
      y: pointY,
      line: line,
    };
  }
  private handleMouseMove(e: any) {
    if (!this.ifCompose) {
      return;
    }
    if (this.ifShowBiaozhuTextDialog || this.ifShowBiaozhuLineDialog) {
      return;
    }
    // 如果不是在绘制中不需要触发计算行为
    if (!this.drawing && !this.startDrawLine) {
      return;
    }
    if (this.startDrawLine) {
      // 画关系连接线
      this.linePath = this.computeLinePath(
        {
          x: this.drawineData.from.content[0][0],
          y: this.drawineData.from.content[1][0],
        },
        { x: e.offsetX, y: e.offsetY }
      );
    } else {
      // 当前点的坐标信息
      const line: any = this.getLineNum(e.offsetY);
      const pointY = this.lineData[line - 1].top - this.fontSize;
      this.endPoint = {
        x: e.offsetX,
        y: pointY,
        line: line,
      };
      // 给选中的文本加上背景色
      const dom: any = document.getElementById("select");
      // 清空绘制路径
      const child: any = dom.childNodes;
      // 如果从前面开始删会导致索引补位，只能删除一半，所以需要从后面开始删除
      if (child && child.length > 0) {
        for (var i = child.length - 1; i >= 0; i--) {
          dom.removeChild(child[i]);
        }
      }
      // 根据当前的起终点拿出符合的数据，并给这些数据绘制底色
      const arr: any = this.getSelectArr(this.startPoint, this.endPoint);
      arr.forEach((d: any) => {
        const x: any = d.startX - 2;
        const y: any = this.lineData[d.line - 1].top - this.fontSize + 2;
        const width: any = d.width + 2;
        drawBg(dom, x, y, width, this.fontSize + 2, "", "#409eff");
      });
    }
  }
  private handleMouseUp(e: any) {
    if (!this.ifCompose) {
      return;
    }
    if (this.ifShowBiaozhuTextDialog || this.ifShowBiaozhuLineDialog) {
      return;
    }
    if (e.which === 3) {
      const d: any = this.ifInBiaozhu(e);
      if (d) {
        this.$confirm("确定删除么？", "删除", {
          customClass: "commonConfirm",
        })
          .then((res: any) => {
            const index = this.getIndex(d.id);
            let id = this.biaozhuData[index].id;
            // 删除当前标注
            if (this.biaozhuData.length === 1) {
              this.biaozhuData = [];
            } else {
              this.biaozhuData.splice(index, 1);
            }
            this.$emit(
              "changeBiaozhu",
              JSON.parse(JSON.stringify(this.biaozhuData))
            );
            if (d.type === "text") {
              // 删除相关的关系标注
              for (var i = this.biaozhuData.length - 1; i >= 0; i--) {
                const item = this.biaozhuData[i];
                if (item.type == "line") {
                  if (item.from.id == id || item.to.id == id) {
                    this.biaozhuData.splice(i, 1);
                    this.$emit(
                      "changeBiaozhu",
                      JSON.parse(JSON.stringify(this.biaozhuData))
                    );
                  }
                }
              }
            }
            return;
          })
          .catch((res: any) => {
            this.drawing = false;
            this.startDrawLine = false;
            return;
          });
        // this.createContent();
      }
    }
    if (this.startDrawLine) {
      return;
    } else {
      // 清空绘制路径
      const dom: any = document.getElementById("select");
      const child: any = dom.childNodes;
      // 如果从前面开始删会导致索引补位，只能删除一半，所以需要从后面开始删除
      if (child && child.length > 0) {
        for (var i = child.length - 1; i >= 0; i--) {
          dom.removeChild(child[i]);
        }
      }
      // 如果不是在绘制中，且没有滑动不需要触发计算行为
      if (!this.drawing || !this.endPoint.x) {
        this.drawing = false;
        return;
      }
      this.drawing = false;
      this.endDrawing();
    }
  }
  private changeClose(item: any, val: any) {
    item.close = val;
    this.$forceUpdate();
  }
  private searchYuyiOptions(e: any) {
    let search = "";
    if (e.target) {
      search = e.target.detail;
    } else {
      search = e;
    }
    const params: any = {
      params: {
        search: search,
        type: "语义类型",
      },
    };
    GetSechemaList(this, params).then((data: any) => {
      this.yuyileixingList = data;
    });
  }
  private leixingChange(item: any) {
    this.dialogData["语义类型"] = {
      _id: item._id,
      label: item.label,
      color: item.color,
    };
    this.dialogData["关联实体"] = {};
    this.dialogData["attrs"] = [];
  }
  private lineChange(e: any, item: any) {
    this.drawineData["关系类型"] = JSON.parse(JSON.stringify(item));
  }
  private searchGuanxiOptions(e: any) {
    let search = "";
    if (e.target) {
      search = e.target.detail;
    } else {
      search = e;
    }
    const params: any = {
      params: {
        search: search,
        type: "关系类型",
      },
    };
    GetSechemaList(this, params).then((data: any) => {
      this.guanxiList = data;
    });
  }
  private addShuxing(i: any) {
    this.dialogData.attrs[i].contents.push({
      属性值: this.dialogData.attrs[i].attribute,
      来源: this.parentData["来源"],
      目标值: "",
    });
  }
  private reduceShuxing(index: any, i: any) {
    this.dialogData.attrs[index].contents.splice(i, 1);
  }
  private change(e: any) {
    this.drawineData["关系类型"] = e;
    this.$forceUpdate();
  }
  private shitiChange(e?: any) {
    this.dialogData["attrs"] = [];
    this.getShuxingOptions(e);
  }
  private getShuxingOptions(e?: any) {
    const params: any = {
      params: {
        _id: this.dialogData["关联实体"].id,
      },
    };
    GetShuxing(this, params).then((res: any) => {
      this.shuxingOptions = res;
      this.$forceUpdate();
    });
  }
  private shuxingChange(e: any) {}
  private resetDrawData() {
    this.linePath = "";
    this.drawing = false;
    this.startDrawLine = false;
    this.clickData = { from: { data: {} }, to: { data: {} } };
    this.dialogData = {
      语义类型: {},
    };
    this.drawineData = {
      to: {},
      from: {},
      关系类型: {},
      备注: "",
    };
  }
  /**打开类型标注弹框 */
  private openBiaozhuText() {
    this.options = [];
    this.shuxingOptions = [];
    this.treeData = JSON.parse(JSON.stringify(this.yuyiData));
    if (!this.dialogData["语义类型"]._id) {
      const params: any = {
        text: this.dialogData.text,
      };
      GetTuijianType(this, params).then((res: any) => {
        res._id = res.id;
        delete res.id;
        this.dialogData["语义类型"] = res;
        this.dialogType = "新建";
        this.ifShowBiaozhuTextDialog = true;
        this.getEntityOption(this.dialogData.text, "", "add");
      });
    } else {
      this.dialogType = "编辑";
      this.$nextTick(() => {
        // 选中
        (this.$refs.yuyiTree as any).setCurrentKey(
          this.dialogData["语义类型"]._id
        );
      });
      this.ifShowBiaozhuTextDialog = true;
      if (this.dialogData["关联实体"].id) {
        this.getEntityOption("", this.dialogData["关联实体"].name);
        this.getShuxingOptions();
      }
    }
  }
  // 打开语义关系标注弹框
  private openBiaozhuLine() {
    this.ifShowBiaozhuLineDialog = true;
    this.treeData = JSON.parse(JSON.stringify(this.guanxiOptions));
    this.$forceUpdate();
    if (this.drawineData["关系类型"]._id) {
      this.$nextTick(() => {
        (this.$refs.guanxiTree as any).setCurrentKey(
          this.drawineData["关系类型"]._id
        );
      });
    }
  }
  private remoteMethod(val: any) {
    this.getEntityOption("", val);
  }
  private remoteShuxingMethod(val: any) {
    // this.getEntityOption("", val);
  }
  private getEntityOption(text: any, search: any, add?: any) {
    const params: any = {
      params: {
        text: text,
        kind: this.dialogData["语义类型"].label,
        search: search || "",
      },
    };
    GetEntity(this, params).then((res: any) => {
      this.options = res;
      if (add && res.length > 0) {
        this.dialogData["关联实体"] = res[0];
        this.getShuxingOptions();
      }
      this.$nextTick(() => {
        // 选中
        (this.$refs.yuyiTree as any).setCurrentKey(
          this.dialogData["语义类型"]._id
        );
      });
      // this.$forceUpdate();
    });
  }
  private ifInBiaozhu(e: any) {
    let data = "";
    this.biaozhuResult.forEach((ele: any) => {
      if (
        e.offsetX > ele.content[0][0] &&
        e.offsetX < ele.content[0][1] &&
        e.offsetY > ele.content[1][0] &&
        e.offsetY < ele.content[1][1]
      ) {
        data = ele;
      }
    });
    return data;
  }
  // 语义类型标注保存
  private biaozhuText() {
    if (!this.dialogData["语义类型"]._id) {
      this.$message.warning("请选择语义类型");
      return;
    }
    const index = this.getIndex(this.dialogData.id);
    if (index == -1) {
      // 如果是新增需要判断重复，如果重复了不需要再添加一个
      // let ifRepeat: any = false;
      // this.biaozhuData.forEach((ele: any) => {
      //   if (
      //     ele.type == "text" &&
      //     ele.text === this.dialogData.text &&
      //     ele.index[0] === this.dialogData.index[0] &&
      //     ele.index[1] === this.dialogData.index[1] &&
      //     ele["语义类型"]._id === this.dialogData["语义类型"]._id
      //   ) {
      //     ifRepeat = true;
      //   }
      // });
      // if (ifRepeat) {
      //   this.ifShowBiaozhuTextDialog = false;
      //   this.dialogData = {
      //     语义类型: {},
      //   };
      //   return;
      // }
      this.biaozhuData.push(JSON.parse(JSON.stringify(this.dialogData)));
    } else {
      const ifEdit: any =
        this.biaozhuData[index]["语义类型"]._id !==
        this.dialogData["语义类型"]._id;
      this.biaozhuData[index] = JSON.parse(JSON.stringify(this.dialogData));
      this.$emit("changeBiaozhu", JSON.parse(JSON.stringify(this.biaozhuData)));
      if (ifEdit) {
        const id: any = this.dialogData.id;
        // let ifRepeat: any = false; // 是否重复了
        // this.biaozhuData.forEach((ele: any, i: any) => {
        //   if (
        //     ele.type == "text" &&
        //     ele.text === this.dialogData.text &&
        //     ele.index[0] === this.dialogData.index[0] &&
        //     ele.index[1] === this.dialogData.index[1] &&
        //     ele["语义类型"]._id === this.dialogData["语义类型"]._id
        //   ) {
        //     ifRepeat = true;
        //   }
        // });
        // // 如果修改的类型当前已经标注，则删除当前语义类型
        // if (ifRepeat) {
        //   this.biaozhuData.splice(index, 1);
        //   this.$emit(
        //     "changeBiaozhu",
        //     JSON.parse(JSON.stringify(this.biaozhuData))
        //   );
        // } else {
        //   this.biaozhuData[index] = JSON.parse(JSON.stringify(this.dialogData));
        //   this.$emit(
        //     "changeBiaozhu",
        //     JSON.parse(JSON.stringify(this.biaozhuData))
        //   );
        // }

        // 如果类型修改了需要删除相关的关系标注
        for (var i = this.biaozhuData.length - 1; i >= 0; i--) {
          const item = this.biaozhuData[i];
          if (item.type == "line") {
            if (item.from.id == id || item.to.id == id) {
              this.biaozhuData.splice(i, 1);
              this.$emit(
                "changeBiaozhu",
                JSON.parse(JSON.stringify(this.biaozhuData))
              );
            }
          }
        }
      }
    }
    this.ifShowBiaozhuTextDialog = false;
    this.$emit("changeBiaozhu", JSON.parse(JSON.stringify(this.biaozhuData)));
    //重新生成源数据
    // this.createContent();
  }
  private drawBiaozhu() {
    // 关闭弹框
    if (this.ifShowBiaozhuTextDialog) {
      this.ifShowBiaozhuTextDialog = false;
    }
    if (this.ifShowBiaozhuLineDialog) {
      this.ifShowBiaozhuLineDialog = false;
    }
    if (this.biaozhuResult.length == 0) {
      return;
    }
    // 画类型标注
    this.biaozhuResult.forEach((item: any) => {
      if (item.type == "text") {
        const typedom: any = document.getElementById("typeDom");
        let shitiW: any = 0;
        if (item["关联实体"] && item["关联实体"].name) {
          shitiW = getStringWidth(
            item["关联实体"].name + 4,
            this.fontSize,
            this.lineicon
          );
        }
        const arr: any = this.strArrData.slice(
          item.index[0],
          item.index[1] + 1
        ); // 根据开始和结束坐标去原始字符串数组截取标记内容数组
        let strLength: any = 0; // 标记内容数组长度
        // 画选中文本的底色
        arr.forEach((d: any) => {
          strLength += d.width;
          const x: any = d.startX - 2;
          const y: any = this.lineData[d.line - 1].top - this.fontSize + 2;
          const width: any = d.width + 2;
          drawBg(
            typedom,
            x,
            y,
            width,
            this.fontSize + 2,
            "",
            item["语义类型"].color
          );
        });
        const typeStartX: any =
          arr[0].startX +
          strLength / 2 -
          (item["语义类型"].label.length * this.fontSize) / 2; // 类型标记的x坐标
        const typeStartY: any =
          this.lineData[arr[0].line - 1].top -
          this.fontSize -
          (item.sort - 1) * this.oneLineBiaozhuH;
        item.content = [
          [
            typeStartX - 2 - shitiW / 2,
            typeStartX -
              2 +
              item["语义类型"].label.length * this.fontSize +
              shitiW +
              4,
          ],
          [
            typeStartY - 16 - this.fontSize,
            typeStartY - 16 - this.fontSize + this.fontSize + 4,
          ],
        ];
        // 画类型标注底色,16是留下画线的空间
        drawBg(
          typedom,
          typeStartX - 2 - shitiW / 2,
          typeStartY - 16 - this.fontSize,
          item["语义类型"].label.length * this.fontSize + 4 + shitiW,
          this.fontSize + 4,
          item["语义类型"].color,
          item["语义类型"].color,
          "4"
        );
        // 画类型标注文本,16是留下画线的空间
        const textDom = document.createElementNS(
          "http://www.w3.org/2000/svg",
          "text"
        );
        textDom.setAttribute("x", (typeStartX - shitiW / 2).toString());
        textDom.setAttribute("y", (typeStartY - 16).toString());
        textDom.setAttribute("font-size", this.fontSize);
        // textDom.setAttribute("fill", "red");
        // 加class
        textDom.setAttribute("class", "cursorPoint");
        let text = "";
        if (item["关联实体"] && item["关联实体"].name) {
          text = item["语义类型"].label + "(" + item["关联实体"].name + ")";
        } else {
          text = item["语义类型"].label;
        }
        textDom.innerHTML = text;
        typedom.appendChild(textDom);
        // 画类型标注括号
        const X: any = arr[0].startX;
        const Y: any = typeStartY - 4;
        const C: any = arr[0].startX + strLength / 2;
        const pathDom = document.createElementNS(
          "http://www.w3.org/2000/svg",
          "path"
        );
        // 'M'50,150 A80,10 0 0,1 100,140 L 103,136 L 106,140 A80,10 0 0,1 156,150
        pathDom.setAttribute(
          "d",
          `M${X - 2},${Y + 2} L ${X},${Y} L ${C - 3},${Y} L ${C},${Y - 4} L ${
            C + 3
          },${Y} L ${X + strLength},${Y} L ${X + strLength + 2},${Y + 2}`
        );
        pathDom.setAttribute("stroke", item["语义类型"].color);
        pathDom.setAttribute("fill", "none");
        typedom.appendChild(pathDom);
      } else {
        const lineDom: any = document.getElementById("lineDom");
        const pathDom = document.createElementNS(
          "http://www.w3.org/2000/svg",
          "path"
        );
        let from: any = [0, 0];
        let to: any = [0, 0];
        let point1: any = [0, 0];
        let point2: any = [0, 0];
        const startY: any =
          this.lineData[item.from.line - 1].top -
          this.fontSize -
          (item.from.sort - 1) * this.oneLineBiaozhuH -
          16 -
          this.fontSize;
        const endY: any =
          this.lineData[item.to.line - 1].top -
          this.fontSize -
          (item.to.sort - 1) * this.oneLineBiaozhuH -
          16 -
          this.fontSize;
        // 中间2个点在行数小的那一行
        if (item.from.biaozhuRangeX[0] > item.to.biaozhuRangeX[0]) {
          from = [item.from.biaozhuRangeX[1], startY];
          to = [item.to.biaozhuRangeX[0], endY];
          point2[0] = item.range[0] - 4;
          point1[0] = item.range[1] + 4;
        } else {
          from = [item.from.biaozhuRangeX[0], startY];
          to = [item.to.biaozhuRangeX[1], endY];
          point1[0] = item.range[0] - 4;
          point2[0] = item.range[1] + 4;
        }
        const sortY =
          this.lineData[item.line - 1].top - item.sort * this.oneLineBiaozhuH;
        point1[1] = sortY;
        point2[1] = sortY;
        pathDom.setAttribute(
          "d",
          `M${from[0]},${from[1]} L ${point1[0]},${point1[1]} L ${point2[0]},${point2[1]} L ${to[0]},${to[1]}`
        );
        pathDom.setAttribute("stroke", "#000");
        pathDom.setAttribute("fill", "none");
        pathDom.setAttribute("fill", "none");
        pathDom.setAttribute("marker-end", "url(#arrow)");
        lineDom.appendChild(pathDom);
        // 画关系标注
        const lineTextDom: any = document.getElementById("lineText");
        const leftPoint = point1[0] > point2[0] ? point2 : point1;
        const rightPoint = point1[0] > point2[0] ? point1 : point2;
        const width: any = getStringWidth(
          item["关系类型"].label,
          this.fontSize,
          this.lineicon
        );
        const x: any =
          leftPoint[0] + (rightPoint[0] - leftPoint[0]) / 2 - width / 2;
        // 画关系标注白底
        item.content = [
          [x, x + width],
          [
            leftPoint[1] - this.fontSize / 2 - 4,
            leftPoint[1] - this.fontSize / 2 - 4 + this.fontSize + 6,
          ],
        ];
        drawBg(
          lineTextDom,
          x,
          leftPoint[1] - this.fontSize / 2 - 4,
          width,
          this.fontSize + 6,
          "",
          "#fff",
          "4"
        );
        // 画关系标注文本
        const textDom = document.createElementNS(
          "http://www.w3.org/2000/svg",
          "text"
        );
        textDom.setAttribute("x", x.toString());
        textDom.setAttribute(
          "y",
          (leftPoint[1] + this.fontSize / 3).toString()
        );
        textDom.setAttribute("font-size", this.fontSize);
        textDom.setAttribute("class", "cursorPoint");
        textDom.innerHTML = item["关系类型"].label;
        lineTextDom.appendChild(textDom);
      }
    });
  }
  // 关系类型标注保存
  private biaozhuLine() {
    if (!this.drawineData["关系类型"]._id) {
      this.$message.warning("请选择关系类型");
      return;
    }
    const obj: any = {
      type: "line",
      from: {
        id: "",
      },
      to: {
        id: "",
      },
      关系类型: this.drawineData["关系类型"],
      备注: this.drawineData["备注"],
      // options: this.guanxiOptions,
    };
    obj.from.id = this.drawineData.from.id;
    obj.to.id = this.drawineData.to.id;
    // if (this.drawineData["关系类型"].reverse) {
    //   obj.from.id = this.drawineData.to.id;
    //   obj.to.id = this.drawineData.from.id;
    // } else {
    //   obj.from.id = this.drawineData.from.id;
    //   obj.to.id = this.drawineData.to.id;
    // }
    // delete this.drawineData["关系类型"].reverse;
    if (this.drawineData.id) {
      obj.id = this.drawineData.id;
      const index = this.getIndex(this.drawineData.id);
      this.biaozhuData[index] = obj;
    } else {
      // 如果是新增需要判断重复，如果重复了不需要再添加一个
      let ifRepeat: any = false;
      this.biaozhuData.forEach((ele: any) => {
        if (
          ele.type == "line" &&
          ele["关系类型"]._id === obj["关系类型"]._id &&
          ele.to.id === obj.to.id &&
          ele.from.id === obj.from.id
        ) {
          ifRepeat = true;
        }
      });
      if (ifRepeat) {
        this.ifShowBiaozhuLineDialog = false;
        this.drawineData = {
          to: {},
          from: {},
          关系类型: {},
          备注: "",
        };
        return;
      }
      obj.id = this.biaozhuData[this.biaozhuData.length - 1].id + 1;
      this.biaozhuData.push(obj);
    }
    //重新生成源数据
    this.createContent();
  }
  private endDrawing() {
    // 判断当前数据是否超出标注字数限制
    const arr: any = this.getSelectArr(this.startPoint, this.endPoint);
    if (arr.length == 0) {
      return;
    }
    let strLength: any = 0;
    let hasHuanhang: any = false;
    this.currentSelectText = "";
    // 计算选中区域的长度
    arr.forEach((opt: any) => {
      if (opt.text == this.lineicon) {
        hasHuanhang = true;
      }
      strLength += opt.width;
      this.currentSelectText += opt.text;
    });
    if (hasHuanhang) {
      this.$message.warning("暂不支持跨段标注！");
      return;
    }
    if (strLength > 900 - this.defaultLeft - this.defaultRight - 20) {
      this.$message.warning("标注内容过长，请重新选择！");
      return;
    }
    // 如果所选文本跨行了，则需要判断与之关联的所有标注的总长度是否超过最大范围
    this.startIndex = arr[0].index;
    this.endIndex = arr[arr.length - 1].index;
    this.getStartIndex(this.startIndex);
    this.getEndIndex(this.endIndex);
    const Length = getStringWidth(
      this.allString.slice(this.startIndex, this.endIndex + 1),
      this.fontSize,
      this.lineicon
    );
    if (Length > 900 - this.defaultLeft - this.defaultRight - 20) {
      this.$message.warning("相关标注内容总长超出最大值，请重新选择！");
      return;
    }
    //记录文本标注信息，需要在保存的时候发送给后端保存
    this.dialogData = {
      type: "text",
      index: [arr[0].index, arr[arr.length - 1].index], // 标记的开始-结束索引
      text: this.currentSelectText, // 标记的文本
      关联实体: {},
      attrs: [],
      语义类型: {}, // 语义类型
      备注: "",
    };
    if (this.biaozhuData.length == 0) {
      this.dialogData.id = 1;
    } else {
      this.dialogData.id = this.biaozhuData[this.biaozhuData.length - 1].id + 1;
    }
    this.openBiaozhuText();
  }
  // 根据始终点循坏原始数据生成符合条件的集合
  private getSelectArr(startPoint: any, endPoint: any) {
    let startLine: any = startPoint.line;
    let endLine: any = endPoint.line;
    let startX: any = startPoint.x;
    let endX: any = endPoint.x;
    let arr: any = [];
    // 区分起终点
    if (startLine == endLine && startX > endX) {
      startX = endPoint.x;
      endX = startPoint.x;
    }
    if (startLine !== endLine && startLine > endLine) {
      startLine = endPoint.line;
      endLine = startPoint.line;
      startX = endPoint.x;
      endX = startPoint.x;
    }
    // 根据调整后的起终点拿取正确的数组内容，需要区分单行和多行
    this.strArrData.forEach((ele: any, index: any) => {
      if (startLine == endLine) {
        if (
          ele.line == startLine &&
          startX < ele.startX + ele.width / 2 &&
          endX > ele.startX + ele.width / 2
        ) {
          arr.push(ele);
        }
      } else {
        // 多行，需要区分是首行（起点~右边），末行（左边~终点）还是中间行（全部）
        if (
          // 首行
          ele.line == startLine &&
          ele.startX + ele.width / 2 > this.startPoint.x
        ) {
          arr.push(ele);
        } else if (
          ele.line == endLine &&
          ele.startX + ele.width / 2 < this.endPoint.x
        ) {
          arr.push(ele);
        } else if (ele.line > startLine && ele.line < endLine) {
          arr.push(ele);
        }
      }
    });
    return arr;
  }
  private computeLinePath(start: any, end: any, lineOffsetY: any = 0) {
    return `M${start.x} ${start.y} Q${start.x + (end.x - start.x) / 2} ${
      end.y * 0.8
    } ${end.x} ${end.y}`;
  }
  // 根据y坐标计算所在行
  private getLineNum(y: any) {
    let line: any = 0;
    this.lineData.forEach((item: any, itemIndex: any) => {
      if ((y > item.range[0] || y == item.range[0]) && y < item.range[1]) {
        line = item.line;
      }
    });
    return line;
  }
  // 清除所有绘图,有参数是清除某部分
  private clearAll(val?: any) {
    const typeDom: any = document.getElementById("typeDom");
    const selectDom: any = document.getElementById("select");
    const textsDom: any = document.getElementById("texts");
    const lineDom: any = document.getElementById("line");
    const relationDom: any = document.getElementById("lineDom");
    const lineTextDom: any = document.getElementById("lineText");

    let typeChild: any = typeDom.childNodes;
    const selectChild: any = selectDom.childNodes;
    const textsChild: any = textsDom.childNodes;
    const lineChild: any = lineDom.childNodes;
    const relationChild: any = relationDom.childNodes;
    const lineTextChild: any = lineTextDom.childNodes;

    let domArr: any = [
      typeDom,
      selectDom,
      textsDom,
      lineDom,
      relationDom,
      lineTextDom,
    ];
    let nodeArr: any = [
      typeChild,
      selectChild,
      textsChild,
      lineChild,
      relationChild,
      lineTextChild,
    ];
    nodeArr.forEach((nodes: any, index: any) => {
      if (nodes && nodes.length > 0) {
        for (var i = nodes.length - 1; i >= 0; i--) {
          domArr[index].removeChild(nodes[i]);
        }
      }
    });
    this.$forceUpdate();
  }
  private resetData() {
    this.linePath = "";
    this.startPoint = {};
    this.endPoint = {};
    this.lineData = [];
    this.startIndex = 0;
    this.endIndex = 0;
    this.drawing = false;
    this.startDrawLine = false;
    this.currentSelectText = "";
    this.ifShowBiaozhuLineDialog = false;
    this.ifShowBiaozhuTextDialog = false;
    this.optionsData = [];
    this.drawineData = {
      to: {},
      from: {},
      关系类型: {},
      备注: "",
    };
    this.dialogData = {
      语义类型: {},
    };
    this.clickData = {
      from: { data: {} },
      to: { data: {} },
    };
    this.$forceUpdate();
  }
  private getStartIndex(index: any) {
    let curIndex = index;
    let hasRange: any = false;
    this.biaozhuData.forEach((item: any, itemIndex: any) => {
      if (
        item.type == "text" &&
        curIndex > item.index[0] &&
        curIndex <= item.index[1] &&
        curIndex > item.index[0]
      ) {
        curIndex = item.index[0];
        this.startIndex = item.index[0];
        hasRange = true;
      }
    });

    if (hasRange) {
      this.getStartIndex(curIndex);
    }
  }
  private getEndIndex(index: any) {
    let curIndex = index;
    let hasRange: any = false;
    this.biaozhuData.forEach((item: any, itemIndex: any) => {
      if (
        item.type == "text" &&
        curIndex >= item.index[0] &&
        curIndex < item.index[1] &&
        curIndex < item.index[1]
      ) {
        curIndex = item.index[1];
        this.endIndex = item.index[1];
        hasRange = true;
      }
    });

    if (hasRange) {
      this.getEndIndex(curIndex);
    }
  }
  // 生成单个字符串的字符串数组
  private getStrArr() {
    this.allString = this.data;
    this.strArrData = [];
    let arr = splitData([this.allString], this.lineicon);
    let line: any = 1;
    let x: any = this.defaultLeft;
    this.lineData = [{ line: 1, num: 0, top: 0, range: [], 标注: [] }];
    arr.forEach((val: any, i: any) => {
      if (val.length < 1) {
        return;
      }
      if (line !== this.lineData[this.lineData.length - 1].line) {
        this.lineData.push({ line: line, num: 0, top: 0, range: [], 标注: [] });
      }
      // 获取字符串文本长度
      let width = getStringWidth(val, this.fontSize, this.lineicon);
      // 生成基础信息数组strArrData，存取基础信息
      if (val.length === 1) {
        const strWidth: any = getStringWidth(val, this.fontSize, this.lineicon);
        if (this.strArrData.length === 0) {
          const strObj: any = {
            index: 0,
            text: val,
            width: strWidth,
            line: line,
            startX: x, // 开始x轴坐标
          };
          if (val == this.lineicon) {
            strObj.width = 0;
          }
          this.strArrData.push(strObj);
        } else {
          const strLast: any = this.strArrData[this.strArrData.length - 1];
          const strObj: any = {
            index: strLast.index + 1,
            text: val,
            width: strWidth,
            line: line,
            startX: strLast.startX + strLast.width, // 开始x轴坐标
          };
          if (val == this.lineicon) {
            strObj.width = 0;
          }
          if (strLast.line != line) {
            strObj.startX = x;
          }
          this.strArrData.push(strObj);
        }
      } else {
        val.split("").forEach((text: any) => {
          const strWidth: any = getStringWidth(
            text,
            this.fontSize,
            this.lineicon
          );
          if (this.strArrData.length === 0) {
            const strObj: any = {
              index: 0,
              text: text,
              width: strWidth,
              line: line,
              startX: x, // 开始x轴坐标
            };
            if (text == this.lineicon) {
              strObj.width = 0;
            }
            this.strArrData.push(strObj);
          } else {
            const strLast: any = this.strArrData[this.strArrData.length - 1];
            const strObj: any = {
              index: strLast.index + 1,
              text: text,
              width: strWidth,
              line: line,
              startX: strLast.startX + strLast.width, // 开始x轴坐标
            };
            if (text == this.lineicon) {
              strObj.width = 0;
            }
            if (strLast.line != line) {
              strObj.startX = x;
            }
            this.strArrData.push(strObj);
          }
        });
      }
      // 计算下一条数据
      // 非最后一项长度超过屏幕内容宽度或者下一个元素是换行符,需要换行
      // 如果下一个有标注，且长度会超过屏幕或者中间有换行符也需要换行
      const strLast: any = this.strArrData[this.strArrData.length - 1];
      let isBiaozhuHuanhang: any = false;
      if (this.biaozhuData.length > 0) {
        let indexRange: any = [];
        this.biaozhuData.forEach((item: any, itemIndex: any) => {
          if (item.type == "text" && item.index[0] == strLast.index + 1) {
            if (indexRange.length === 0) {
              indexRange = JSON.parse(JSON.stringify(item.index));
            }
          }
        });
        let W: any = 0;
        if (indexRange.length > 0) {
          this.endIndex = indexRange[1];
          this.getEndIndex(this.endIndex);
          indexRange[1] = this.endIndex;
          W = getStringWidth(
            this.allString.slice(indexRange[0], indexRange[1] + 1),
            this.fontSize,
            this.lineicon
          );
        }
        if (x + W > this.W - this.defaultRight) {
          isBiaozhuHuanhang = true;
        }
      }
      if (
        i !== arr.length - 1 &&
        (x + getStringWidth(arr[i + 1], this.fontSize, this.lineicon) >
          this.W - this.defaultRight ||
          val == this.lineicon ||
          isBiaozhuHuanhang)
      ) {
        line += 1;
        x = this.defaultLeft;
      } else {
        x += width;
      }
    });
  }
  // 处理标记数据，形成可直接绘制标记的数据
  private getBiaojiArr() {
    this.biaozhuResult = [];
    if (this.biaozhuData.length == 0) {
      return;
    }
    let arr: any = [];
    // 先循环处理出类型标注的内容
    this.biaozhuData.forEach((item: any, index: any) => {
      if (item.type == "text") {
        const line: any = this.strArrData[item.index[0]].line;
        let range: any = []; // 整个标记的最大x轴范围
        // 计算标记的最大范围的起始坐标,如果选中文本比标记文本长则用选中文本的，否则用标记文本的，此处是用来判断是否会重合，从而加一行
        const w: any = getStringWidth(item.text, this.fontSize, this.lineicon); // 被标记文本的长度
        let w1: any = 0; // 关联概念实体长度
        if (item["关联实体"] && item["关联实体"].name) {
          w1 = getStringWidth(
            "(" + item["关联实体"].name + ")",
            this.fontSize,
            this.lineicon
          );
        }
        const w2: any =
          getStringWidth(item["语义类型"].label, this.fontSize, this.lineicon) +
          w1 +
          4; // 标记内容长度,4是大约标记边框的大小
        const x: any = this.strArrData[item.index[0]].startX; //被标记文本的起始x坐标
        if (w < w2) {
          range = [x + w / 2 - w2 / 2, x + w / 2 + w2 / 2];
        } else {
          range = [x, x + w];
        }
        let obj: any = JSON.parse(JSON.stringify(item));
        obj.line = line; //  是指标记所选文字行数
        obj.width = w; // range 是指整个标记的起始点
        obj["语义类型"].width = w2; // range 是指整个标记的起始点
        obj.range = range; // range 是指整个标记的起始点
        obj.startX = x; // range 是指整个标记的起始点
        arr.push(obj);
      } else {
        arr.push(JSON.parse(JSON.stringify(item)));
      }
    });
    // 循环处理出关系标注的内容
    arr.forEach((ele: any, i: any) => {
      if (ele.type == "line") {
        ele.from = arr[this.getIndex(ele.from.id)];
        ele.to = arr[this.getIndex(ele.to.id)];
        ele.range = [0, 0];
        // 哪个类型标注的行数偏小类型标注就在哪行
        if (ele.from.line > ele.to.line) {
          ele.line = ele.to.line;
        } else {
          ele.line = ele.from.line;
        }
        const fromLeft: any =
          ele.from.startX +
          getStringWidth(ele.from.text, this.fontSize, this.lineicon) / 2 -
          (ele.from["语义类型"].label.length * this.fontSize) / 2 -
          2;
        const fromRight: any =
          ele.from.startX +
          getStringWidth(ele.from.text, this.fontSize, this.lineicon) / 2 +
          (ele.from["语义类型"].label.length * this.fontSize) / 2 +
          4;
        const toLeft: any =
          ele.to.startX +
          getStringWidth(ele.to.text, this.fontSize, this.lineicon) / 2 -
          (ele.to["语义类型"].label.length * this.fontSize) / 2 -
          2;
        const toRight: any =
          ele.to.startX +
          getStringWidth(ele.to.text, this.fontSize, this.lineicon) / 2 +
          (ele.to["语义类型"].label.length * this.fontSize) / 2 +
          4;
        ele.from.biaozhuRangeX = [fromLeft, fromRight];
        ele.to.biaozhuRangeX = [toLeft, toRight];

        if (fromLeft > toLeft) {
          ele.range[0] = toLeft;
        } else {
          ele.range[0] = fromLeft;
        }
        if (fromRight > toRight) {
          ele.range[1] = fromRight;
        } else {
          ele.range[1] = toRight;
        }
        // 如果关系标注的文本长度更长，则需要按照关系标注文本的长度来算范围
        const textW: any = getStringWidth(
          ele["关系类型"].label,
          this.fontSize,
          this.lineicon
        );
        // 12是关系标注文本两端至少各留6
        if (textW + 12 > ele.range[1] - ele.range[0]) {
          const x = ele.range[0];
          const x2 = ele.range[1];
          ele.range = [
            x + (x2 - x) / 2 - (textW + 12) / 2,
            x + (x2 - x) / 2 + (textW + 12) / 2,
          ];
        }
        ele.width = ele.range[1] - ele.range[0];
        ele.startX = ele.range[0];
      }
    });
    // 给标记排列
    const biaozhuArr: any = [[]];
    arr.forEach((item: any, itemIndex: any) => {
      let sort: any = 1;
      if (arr.length !== 0) {
        // 同行已经有标记，且和当前区域有重合需要加一行
        for (var i = 0; i < biaozhuArr.length; i++) {
          let rep: any = false;
          for (var j = 0; j < biaozhuArr[i].length; j++) {
            const ifRepeat: any = isIntersect(
              item.range,
              biaozhuArr[i][j].range
            );
            if (ifRepeat && item.line == biaozhuArr[i][j].line) {
              rep = true;
            }
          }
          if (!rep) {
            if (sort > i + 1) {
              sort = i + 1;
            }
          } else {
            if (sort == i + 1) {
              sort = i + 2;
            }
          }
        }
      }
      item.sort = sort;
      if (item.sort > biaozhuArr.length) {
        biaozhuArr.push([item]);
      } else {
        biaozhuArr[sort - 1].push(item);
      }
      if (this.lineData[item.line - 1]["标注"][sort - 1]) {
        this.lineData[item.line - 1]["标注"][sort - 1].push(item);
      } else {
        this.lineData[item.line - 1]["标注"].push([item]);
      }
      this.biaozhuResult.push(item);
    });
  }
  // 最终生成行数据,getStrArr会生成line字段，getBiaojiArr会生成标注字段，这里是生成剩下的top和range字段
  private getLineData() {
    this.lineData.forEach((ele: any, index: any) => {
      if (index === 0) {
        const top =
          this.defaultTop +
          this.fontSize +
          ele["标注"].length * this.oneLineBiaozhuH;
        ele.top = top;
        if (this.lineData.length > 1) {
          const nextSpace: any =
            (this.lineData[index + 1]["标注"].length * this.oneLineBiaozhuH +
              this.defaultTop +
              this.fontSize) /
            2;
          ele.range = [0, top + nextSpace];
        } else {
          ele.range = [0, top + 40];
        }
      } else if (index == this.lineData.length - 1) {
        const before: any = this.lineData[index - 1];
        const top: any =
          before.top +
          this.defaultTop +
          this.fontSize +
          ele["标注"].length * this.oneLineBiaozhuH;
        ele.top = top;
        ele.range = [before.range[1], top + 40];
      } else {
        const before: any = this.lineData[index - 1];
        const top: any =
          before.top +
          this.defaultTop +
          this.fontSize +
          ele["标注"].length * this.oneLineBiaozhuH;
        const nextSpace: any =
          (this.lineData[index + 1]["标注"].length * this.oneLineBiaozhuH +
            this.defaultTop +
            this.fontSize) /
          2;
        ele.top = top;
        ele.range = [before.range[1], top + nextSpace];
      }
    });
    this.contentHeight = this.lineData[this.lineData.length - 1].top + 40;
  }
  // 渲染文本内容
  private createText() {
    const element: any = document.getElementById("texts");
    // 渲染文本内容
    this.strArrData.forEach((ele: any, i: any) => {
      if (ele.text == this.lineicon) {
        return;
      }
      drawText(
        element,
        ele.startX,
        this.lineData[ele.line - 1].top,
        ele.text,
        this.fontSize,
        "#333"
      );
    });
  }
  private createContent() {
    if (!this.data) {
      return;
    }
    this.resetData();
    // 需要重新绘制的时候需要清除之前的所有路径
    this.clearAll();
    // 生成单个字符串的字符串数组
    this.getStrArr();
    // 处理标记数据，形成可直接绘制标记的数据
    this.getBiaojiArr();
    // 生成行数据
    this.getLineData();
    this.createText();
    // 绘制标注
    this.drawBiaozhu();
  }
  // 通过id拿标注对应的index
  private getIndex(id: any) {
    let i = -1;
    this.biaozhuData.forEach((ele: any, index: any) => {
      if (ele.id === id) {
        i = index;
      }
    });
    return i;
  }
  private getTreeOption(val: any) {
    const params: any = {
      kind: val,
    };
    if (val == "关系类型") {
      params.from_text = this.drawineData.from["语义类型"].label;
      params.to_text = this.drawineData.to["语义类型"].label;
      delete params.kind;
    }
    GetBiaozhuOption(this, params).then((res: any) => {
      if (val == "语义类型") {
        this.yuyiData = res;
      } else {
        // 需要选上默认值
        res.forEach((ele: any) => {
          ele.relation = ele["类别"];
        });
        this.guanxiOptions = res;
        // 循环找出有没有推荐的
        if (this.dialogType == "新建") {
          let tuijian: any = [];
          res.forEach((element: any) => {
            element.children.forEach((ele: any) => {
              if (ele.rec) {
                tuijian.push(ele);
              }
            });
          });
          if (tuijian.length > 0) {
            this.drawineData["关系类型"] = {
              _id: tuijian[0].id,
              label: tuijian[0].relation,
            };
          }
        }
        this.openBiaozhuLine();
      }
      this.$forceUpdate();
    });
  }
  private getLineOption() {
    const params: any = {
      kind: "关系类型",
    };
    GetBiaozhuOption(this, params).then((res: any) => {
      this.guanxiOptions = res;
    });
  }
  private mounted() {
    const W = (this.$refs.biaozhuBox as any).offsetWidth;
    if (W > 900) {
      this.W = W;
    } else {
      this.W = 900;
    }
    this.getTreeOption("语义类型");
    // 禁掉浏览器的选中文字效果
    document.onselectstart = function () {
      return false;
    };
    // this.getLineOption();
  }
}
