/**
 * @description 绘制文本底色
 * @returns 
 */
const drawBg = (dom: any,
    x: any,
    y: any,
    width: any,
    height: any,
    strokeColor: any,
    fillColor: any,
    radius?: any): any => {
    const rectDom = document.createElementNS(
        "http://www.w3.org/2000/svg",
        "rect"
    );
    rectDom.setAttribute("x", x.toString());
    rectDom.setAttribute("y", y.toString());
    rectDom.setAttribute("width", width.toString());
    rectDom.setAttribute("height", height.toString());
    if (radius) {
        rectDom.setAttribute("rx", radius);
        rectDom.setAttribute("ry", radius);
    }
    if (strokeColor) {
        rectDom.setAttribute("stroke", strokeColor);
    }
    if (fillColor) {
        rectDom.setAttribute("fill", fillColor);
    }
    dom.appendChild(rectDom);
}
/**
 * @description 绘制文本
 * @returns 
 */
const drawText = (ele: any, x: any, y: any, text: any, fontSize: any, color: any): any => {
    const textDom = document.createElementNS(
        "http://www.w3.org/2000/svg",
        "text"
    );
    textDom.setAttribute("x", x.toString());
    textDom.setAttribute("y", y.toString());
    textDom.setAttribute("font-size", fontSize);
    textDom.setAttribute("fill", color);
    // 加class 用来控制鼠标悬浮字体变大
    //   textDom.setAttribute("class", "enlarge");
    textDom.innerHTML = text;
    ele.appendChild(textDom);
}
/**
* @description 获取单个字符长度
*/
const getWidth = (text: any, fontSize: any, lineicon: any) => {
    let width: any = 0;
    if (text == "W") {
        width = 15;
    } else if (text == "m" || text == "M") {
        width = 13;
    } else if (text == "O" || text == "G" || text == "Q") {
        width = 12.4;
    } else if (text == "R" || text == "N" || text == "U") {
        width = 11.5;
    } else if (
        text == "w" ||
        text == "C" ||
        text == "D" ||
        text == "E" ||
        text == "H"
    ) {
        width = 11;
    } else if (
        text == "B" ||
        text == "K" ||
        text == "P" ||
        text == "S" ||
        text == "V" ||
        text == "X" ||
        text == "Y"
    ) {
        width = 10.5;
    } else if (text == "A") {
        width = 10;
    } else if (text == "T" || text == "Z") {
        width = 9.4;
    } else if (
        text == "0" ||
        text == "1" ||
        text == "2" ||
        text == "3" ||
        text == "4" ||
        text == "5" ||
        text == "6" ||
        text == "7" ||
        text == "8" ||
        text == "9" ||
        text == "a" ||
        text == "b" ||
        text == "d" ||
        text == "e" ||
        text == "g" ||
        text == "h" ||
        text == "m" ||
        text == "n" ||
        text == "o" ||
        text == "p" ||
        text == "q" ||
        text == "u" ||
        text == "L" ||
        text == "F"
    ) {
        width = 8.89;
    } else if (
        text == "c" ||
        text == "k" ||
        text == "s" ||
        text == "v" ||
        text == "x" ||
        text == "y" ||
        text == "z" ||
        text == "J"
    ) {
        width = 8;
    } else if (text == "r") {
        width = 5;
    } else if (text == "f" || text == "t" || text == "I") {
        width = 4;
    } else if (text == "i" || text == "j" || text == "l") {
        width = 3.5;
    } else if (text == " ") {
        width = 10;
    } else if (text == ".") {
        width = 6;
    } else if (text == lineicon) {
        width = 0;
    } else {
        const zhongwen: any = /[\u4E00-\u9FA5\uF900-\uFA2D]/;
        // if (!zhongwen.test(text)) {
        //   width = this.fontSize / 2;
        // } else {
        //   width = this.fontSize;
        // }
        width = fontSize;
    }
    return width;
}
/**
 * @description 获取字符串长度
 */
const getStringWidth = (str: any, fontSize: any, lineicon: any) => {
    let width: any = 0;
    const arr: any = str.split("");
    arr.forEach((ele: any) => {
        width += getWidth(ele, fontSize, lineicon);
    });
    return width;
}
/**
 * @description  
 * @params arrData字符串数组
 */
const getData = (arrData: any, tip: any) => {
    let arr: any = [];
    arrData.forEach((element: any) => {
        const d = element.split(tip);
        let dArr: any = [];
        d.forEach((ele: any, i: any) => {
            if (i !== 0 && tip) {
                dArr.push(tip);
            }
            dArr.push(ele);
        });
        arr = arr.concat(dArr);
    });
    return arr;
}
/**
 * @description 分割字符串
 * @params arrData字符串数组
 */
const splitData = (arrData: any, lineicon: any) => {
    let data: any = arrData;
    // 根据这些切割字符串
    const tips: any = [
        " ",
        lineicon,
        "。",
        ",",
        "，",
        "?",
        "？",
        ".",
        "/",
        "*",
        "!",
        "！",
        "@",
        "#",
        "$",
        "%",
        "&",
        "-",
        "——",
        "(",
        ")",
        "（",
        "）",
        '"',
        "”",
        "“",
        ";",
        "；",
        ":",
        "：",
    ];
    let arr: any = [];
    tips.forEach((ele: any) => {
        const d = getData(data, ele);
        data = d;
        arr = d;
    });
    // 最后切割非英文单词的字符串
    let myD: any = [];
    const numRex: any = /^[0-9]*$/;
    const word: any = /^[a-zA-Z]+$/;
    arr.forEach((item: any, index: any) => {
        if (item.length > 1 && item[index - 1] != " " && item[index + 1] != " ") {
            if (!numRex.test(item) && !word.test(item)) {
                //拆开
                myD = myD.concat(getData([item], ""));
            } else {
                myD.push(item);
            }
        } else {
            myD.push(item);
        }
    });
    // 如果最后一项是空则截取掉
    if (!myD[myD.length - 1]) {
        myD.pop();
    }
    return myD;
}
// 判断2个数字区间是否有重叠
const isIntersect = (arr1: any, arr2: any) => {
    let start = [Math.min(...arr1), Math.min(...arr2)]; //区间的两个最小值【4,6】【2,8】
    let end = [Math.max(...arr1), Math.max(...arr2)]; //区间的两个最大值
    return Math.max(...start) <= Math.min(...end); //最大值里的最小值 是否 小于等于 最大值的最小值
}
export {
    drawBg, drawText, getStringWidth, getWidth, splitData, getData, isIntersect
};