





































import { Component, Vue, Prop } from "vue-property-decorator";
@Component({})
export default class Name extends Vue {
  @Prop()
  private data: any;
  @Prop({ type: Boolean, default: false })
  private ifShowFenpeiStatu: any;
}
